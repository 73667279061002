import './css/q5.scss';

import React, { useState } from 'react';
import IconChoose from 'src/assets/img/icon-choose.png';
import A1 from 'src/assets/img/q5/a1.png';
import A2 from 'src/assets/img/q5/a2.png';
import A3 from 'src/assets/img/q5/a3.png';
import A4 from 'src/assets/img/q5/a4.png';
import BG1 from 'src/assets/img/q5/bg1.png';
import BG2 from 'src/assets/img/q5/bg2.png';
import BG3 from 'src/assets/img/q5/bg3.png';
import BG4 from 'src/assets/img/q5/bg4.png';
import Castle1 from 'src/assets/img/q5/c1.png';
import Castle2 from 'src/assets/img/q5/c2.png';
import Castle3 from 'src/assets/img/q5/c3.png';
import Castle4 from 'src/assets/img/q5/c4.png';

const PickItem = ({ imgSrc, isActive, onClick, iconChooseSrc, altText }) => {
  return (
    <div className={`pick-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <img src={imgSrc} alt={altText} />
      {isActive && <img src={iconChooseSrc} alt="Choose Icon" className="icon-choose" />}
    </div>
  );
};

const Q5 = (props) => {
  const [ansIndex, setAnsIndex] = useState(1);
  const [castleType, setCastleType] = useState(Castle1);
  const [answerText, setAnswerText] = useState('歡樂的飛船城堡');

  const chooseAnswer = (num) => {
    setAnsIndex(num);
    switch (num) {
      case 1:
        setCastleType(Castle1);
        setAnswerText('歡樂的飛船城堡');
        break;
      case 2:
        setCastleType(Castle2);
        setAnswerText('堅固的木屋城堡');
        break;
      case 3:
        setCastleType(Castle3);
        setAnswerText('古老的神殿城堡');
        break;
      case 4:
        setCastleType(Castle4);
        setAnswerText('彩色玻璃城堡');
        break;
      default:
        break;
    }
  }

  const confirmAnswer = () => {
    props.pickAnswer(ansIndex);
  }

  return (
    <>
      <div className="castle-bg">
        <img src={castleType === Castle1 ? BG1 : castleType === Castle2 ? BG2 : castleType === Castle3 ? BG3 : BG4} alt="Castle Background" />
      </div>
      <div className="q5-container mt-50">
        <p className="quiz-p">
          穿越雲層，眼前出現了一座城堡，<br />你想像是什麼樣子？
        </p>
        <div className="castle-type">
          <img src={castleType} alt="Castle" />
        </div>
        <p className="answer-text">
          {answerText}
        </p>
        <div className="pick-container">
          {
            Array.from({ length: 4 }, (v, i) => (
              <PickItem
                key={i}
                imgSrc={i === 0 ? A1 : i === 1 ? A2 : i === 2 ? A3 : A4}
                isActive={castleType === (i === 0 ? Castle1 : i === 1 ? Castle2 : i === 2 ? Castle3 : Castle4)}
                onClick={() => { chooseAnswer(i + 1) }}
                iconChooseSrc={IconChoose}
                altText={`Pick Item ${i + 1}`}
              />
            ))
          }
        </div>

        <button onClick={confirmAnswer} className="confirm-btn">就是這樣！</button>

      </div>
    </>
  );
}

export default Q5;
