import './css/quiz.scss';

import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderQ from 'src/components/header-q';
import Q1 from 'src/components/quiz/q1';
import Q10 from 'src/components/quiz/q10';
import Q2 from 'src/components/quiz/q2';
import Q3 from 'src/components/quiz/q3';
import Q4 from 'src/components/quiz/q4';
import Q5 from 'src/components/quiz/q5';
import Q6 from 'src/components/quiz/q6';
import Q7 from 'src/components/quiz/q7';
import Q8 from 'src/components/quiz/q8';
import Q9 from 'src/components/quiz/q9';

// 八個題目的預設答案
let pickAnswerArr = [0, 0, 0, 0, 0, 0, 0, 0];

const Quiz = (props) => {
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState(''); // 玩家名稱
  const [currentQ, setCurrentQ] = useState(1); // 當前的題目編號

  // 切換下一題
  const nextQ = () => {
    if (currentQ < 10) {
      setCurrentQ(currentQ + 1);
      props.currentQuiz(currentQ + 1);
    } else {
      // 要回到最頂部
      window.scrollTo(0, 0);

      navigate('/');
    }
  };

  let totalScoreOfSecretMage = 0;
  let totalScoreOfElementalExplorer = 0;
  let totalScoreOfSoulHealer = 0;
  let totalScoreOfHolyShield = 0;

  const [totalScoreArr, setTotalScoreArr] = useState([totalScoreOfSecretMage, totalScoreOfElementalExplorer, totalScoreOfSoulHealer, totalScoreOfHolyShield]);
  // 處理使用者選擇的答案
  const handlePickAnswer = (num) => {
    pickAnswerArr[currentQ - 2] = num;

    let questionScoreArr1 = [1, 1.05, 1, 1.1];
    let questionScoreArr2 = [1.25, 1.1, 1.05, 1.1];
    let questionScoreArr3 = [1, 1, 1, 1.1];
    let questionScoreArr4 = [1.5, 1.5, 1.5, 1.5];
    let questionScoreArr5 = [1, 1.1, 1.05, 1];
    let questionScoreArr6 = [1.1, 1.05, 1.25, 1.15];
    let questionScoreArr7 = [1.01, 1.02, 1.01, 1.1];
    let questionScoreArr8 = [1, 1.1, 1, 1];

    // 職業對應 questionScoreArr 位置的分數
    const scoreSecretMage = [1, 4, 4, 4, 1, 3, 1, 4];
    const scoreElementalExplorer = [2, 1, 1, 1, 2, 1, 4, 1];
    const scoreSoulHealer = [3, 2, 3, 3, 4, 2, 3, 2];
    const scoreHolyShield = [4, 3, 2, 2, 3, 4, 2, 3];

    if (pickAnswerArr[0] === 1) {
      totalScoreOfSecretMage += questionScoreArr1[scoreSecretMage[0] - 1];
    } else if (pickAnswerArr[0] === 2) {
      totalScoreOfElementalExplorer += questionScoreArr1[scoreElementalExplorer[0] - 1];
    } else if (pickAnswerArr[0] === 3) {
      totalScoreOfSoulHealer += questionScoreArr1[scoreSoulHealer[0] - 1];
    } else if (pickAnswerArr[0] === 4) {
      totalScoreOfHolyShield += questionScoreArr1[scoreHolyShield[0] - 1];
    }

    if (pickAnswerArr[1] === 1) {
      totalScoreOfElementalExplorer += questionScoreArr2[scoreElementalExplorer[1] - 1];
    } else if (pickAnswerArr[1] === 2) {
      totalScoreOfSoulHealer += questionScoreArr2[scoreSoulHealer[1] - 1];
    } else if (pickAnswerArr[1] === 3) {
      totalScoreOfHolyShield += questionScoreArr2[scoreHolyShield[1] - 1];
    } else if (pickAnswerArr[1] === 4) {
      totalScoreOfSecretMage += questionScoreArr2[scoreSecretMage[1] - 1];
    }

    if (pickAnswerArr[2] === 1) {
      totalScoreOfElementalExplorer += questionScoreArr3[scoreElementalExplorer[2] - 1];
    } else if (pickAnswerArr[2] === 2) {
      totalScoreOfHolyShield += questionScoreArr3[scoreHolyShield[2] - 1];
    } else if (pickAnswerArr[2] === 3) {
      totalScoreOfSoulHealer += questionScoreArr3[scoreSoulHealer[2] - 1];
    } else if (pickAnswerArr[2] === 4) {
      totalScoreOfSecretMage += questionScoreArr3[scoreSecretMage[2] - 1];
    }

    if (pickAnswerArr[3] === 1) {
      totalScoreOfElementalExplorer += questionScoreArr4[scoreElementalExplorer[3] - 1];
    } else if (pickAnswerArr[3] === 2) {
      totalScoreOfHolyShield += questionScoreArr4[scoreHolyShield[3] - 1];
    } else if (pickAnswerArr[3] === 3) {
      totalScoreOfSoulHealer += questionScoreArr4[scoreSoulHealer[3] - 1];
    } else if (pickAnswerArr[3] === 4) {
      totalScoreOfSecretMage += questionScoreArr4[scoreSecretMage[3] - 1];
    }

    if (pickAnswerArr[4] === 1) {
      totalScoreOfSecretMage += questionScoreArr5[scoreSecretMage[4] - 1];
    } else if (pickAnswerArr[4] === 2) {
      totalScoreOfElementalExplorer += questionScoreArr5[scoreElementalExplorer[4] - 1];
    } else if (pickAnswerArr[4] === 3) {
      totalScoreOfHolyShield += questionScoreArr5[scoreHolyShield[4] - 1];
    } else if (pickAnswerArr[4] === 4) {
      totalScoreOfSoulHealer += questionScoreArr5[scoreSoulHealer[4] - 1];
    }

    if (pickAnswerArr[5] === 1) {
      totalScoreOfElementalExplorer += questionScoreArr6[scoreElementalExplorer[5] - 1];
    } else if (pickAnswerArr[5] === 2) {
      totalScoreOfSoulHealer += questionScoreArr6[scoreSoulHealer[5] - 1];
    } else if (pickAnswerArr[5] === 3) {
      totalScoreOfSecretMage += questionScoreArr6[scoreSecretMage[5] - 1];
    } else if (pickAnswerArr[5] === 4) {
      totalScoreOfHolyShield += questionScoreArr6[scoreHolyShield[5] - 1];
    }

    if (pickAnswerArr[6] === 1) {
      totalScoreOfSecretMage += questionScoreArr7[scoreSecretMage[6] - 1];
    } else if (pickAnswerArr[6] === 2) {
      totalScoreOfHolyShield += questionScoreArr7[scoreHolyShield[6] - 1];
    } else if (pickAnswerArr[6] === 3) {
      totalScoreOfSoulHealer += questionScoreArr7[scoreSoulHealer[6] - 1];
    } else if (pickAnswerArr[6] === 4) {
      totalScoreOfElementalExplorer += questionScoreArr7[scoreElementalExplorer[6] - 1];
    }

    if (pickAnswerArr[7] === 1) {
      totalScoreOfElementalExplorer += questionScoreArr8[scoreElementalExplorer[7] - 1];
    } else if (pickAnswerArr[7] === 2) {
      totalScoreOfSoulHealer += questionScoreArr8[scoreSoulHealer[7] - 1];
    } else if (pickAnswerArr[7] === 3) {
      totalScoreOfHolyShield += questionScoreArr8[scoreHolyShield[7] - 1];
    } else if (pickAnswerArr[7] === 4) {
      totalScoreOfSecretMage += questionScoreArr8[scoreSecretMage[7] - 1];
    }

    // console.log('totalScoreOfSecretMage:', totalScoreOfSecretMage);
    // console.log('totalScoreOfElementalExplorer:', totalScoreOfElementalExplorer);
    // console.log('totalScoreOfSoulHealer:', totalScoreOfSoulHealer);
    // console.log('totalScoreOfHolyShield:', totalScoreOfHolyShield);

    setTotalScoreArr([totalScoreOfSecretMage, totalScoreOfElementalExplorer, totalScoreOfSoulHealer, totalScoreOfHolyShield]);

    nextQ();
  };

  // 完成所有題目
  const handleFinishAnswer = () => {
    props.goToResult(playerName);
    handleCalculateResult(playerName);

    // navigate(`/result?name=${playerName}&result=123`);
  };

  // 處理計算結果的 Function
  // https://docs.google.com/spreadsheets/d/1f3THWuBJ69f4poni3EiZpHUjeajkxQZb6CutsuOun8s/edit?gid=712171830#gid=712171830
  const handleCalculateResult = (playerName) => {
    // 結果對應的職業
    // '祕法智者', '元素探險者', '靈魂治療師', '聖盾守護者
    let resultJobArr = ['SecretMage', 'ElementalExplorer', 'SoulHealer', 'HolyShield'];

    // 找出 totalScoreOfSecretMage, totalScoreOfElementalExplorer, totalScoreOfSoulHealer, totalScoreOfHolyShield 中最大值
    let maxIndex = 0;

    // console.log('job:', totalScoreArr);

    // 找出最大值的 index
    for (let i = 0; i < totalScoreArr.length; i++) {
      if (totalScoreArr[i] > totalScoreArr[maxIndex]) {
        maxIndex = i;
      }
    }

    // 最終結果
    let resultJob = resultJobArr[maxIndex];

    // 跳轉到結果頁面
    navigate(`/result?name=${playerName}&result=${resultJob}`);
  }

  // 動畫參數：淡入淡出效果
  const variants = {
    initial: { opacity: 0, y: 100 }, // 初始状态：透明并向下偏移
    animate: { opacity: 1, y: 0 },   // 進入狀態：不透明並向上偏移
    exit: { opacity: 0, y: -100 },   // 退出狀態：透明并向上偏移
  };

  // 題目組件
  const quizComponents = [
    <Q1 nextQ={nextQ} playerName={playerName} setPlayerName={setPlayerName} />,
    <Q2 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q3 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q4 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q5 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q6 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q7 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q8 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q9 nextQ={nextQ} pickAnswer={handlePickAnswer} />,
    <Q10 nextQ={nextQ} allQuestionsAnswered={handleFinishAnswer} />,
  ];

  // 返回上一題
  // const handleBackLastQ = () => {
  //   if (currentQ === 1) {
  //     navigate('/');
  //     return;
  //   }

  //   if (currentQ > 1) {
  //     setCurrentQ(currentQ - 1);
  //   }
  // };

  return (
    <>
      <HeaderQ currentQ={currentQ} />
      <div className="quiz-container">
        {
          currentQ === 10 ?
            <>{quizComponents[currentQ - 1]}</>
            :
            <motion.div
              key={currentQ}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              {quizComponents[currentQ - 1]}
            </motion.div>
        }
      </div>

      {/* TODO: 返回上一題 (上綫要刪除) */}
      {/* <button onClick={() => { handleBackLastQ() }} className="back-btn">返回上一題</button> */}
      {/* TODO: 跳過題目 (上綫要刪除) */}
      {/* <button onClick={nextQ} className="skip-btn">跳過</button> */}
    </>
  );
};

export default Quiz;
