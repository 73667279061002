import BoWenImg from 'src/assets/img/ar/bowen.png';
import BtnCapture from 'src/assets/img/ar/btn-capture.png';
import F1Img from 'src/assets/img/ar/f1.png';
import F2Img from 'src/assets/img/ar/f2.png';
import F3Img from 'src/assets/img/ar/f3.png';
import F4Img from 'src/assets/img/ar/f4.png';
import IconReturn from 'src/assets/img/ar/icon-return.png';
import IconSave from 'src/assets/img/ar/icon-save.png';
import CheerIcon2 from 'src/assets/img/cheer/cheer-b2.png';
import CheerIcon4 from 'src/assets/img/cheer/cheer-g4.png';
import CheerIcon3 from 'src/assets/img/cheer/cheer-r3.png';
import CheerIcon1 from 'src/assets/img/cheer/cheer-y1.png';
import CheerBG1 from 'src/assets/img/cheer/cheer1.jpg';
import CheerBG2 from 'src/assets/img/cheer/cheer2.jpg';
import CheerBG3 from 'src/assets/img/cheer/cheer3.jpg';
import CheerBG4 from 'src/assets/img/cheer/cheer4.jpg';
import Gif2 from 'src/assets/img/cheer/effect-b2.gif';
import Gif4 from 'src/assets/img/cheer/effect-g4.gif';
import Gif3 from 'src/assets/img/cheer/effect-r3.gif';
import Gif1 from 'src/assets/img/cheer/effect-y1.gif';
import IconLoading from 'src/assets/img/icon-loading.png';
import IconMusicOff from 'src/assets/img/icon-music-off.png';
import IconMusic from 'src/assets/img/icon-music.png';
import DreamJob from 'src/assets/img/main/dream.png';
import MusicCloud from 'src/assets/img/main/music-cloud.png';
import MusicImg from 'src/assets/img/main/music.png';
import QRCode from 'src/assets/img/main/QRcode.png';
import StartBtn from 'src/assets/img/main/start-btn.png';
import Title from 'src/assets/img/main/title.png';
import TopTitleImg from 'src/assets/img/main/top-title.png';
import BearTree from 'src/assets/img/main/xmasTree.gif';
import Q1Cloud from 'src/assets/img/q1/box.png';
import Q1Title from 'src/assets/img/q1/title.png';
import TouchImg from 'src/assets/img/q10/touch.gif';
import Vector from 'src/assets/img/q10/vector.png';
import A21 from 'src/assets/img/q2/a1.png';
import A22 from 'src/assets/img/q2/a2.png';
import A23 from 'src/assets/img/q2/a3.png';
import A24 from 'src/assets/img/q2/a4.png';
import DeerHead from 'src/assets/img/q2/deer-head.png';
import DeerIcon from 'src/assets/img/q2/deer-icon.png';
import Q2Vector from 'src/assets/img/q2/vector.png';
import A31 from 'src/assets/img/q3/a1.png';
import A32 from 'src/assets/img/q3/a2.png';
import A33 from 'src/assets/img/q3/a3.png';
import A34 from 'src/assets/img/q3/a4.png';
import Deer1 from 'src/assets/img/q3/deer1.png';
import Deer2 from 'src/assets/img/q3/deer2.png';
import Deer3 from 'src/assets/img/q3/deer3.png';
import Deer4 from 'src/assets/img/q3/deer4.png';
import Car from 'src/assets/img/q4/car.png';
import A1 from 'src/assets/img/q5/a1.png';
import A2 from 'src/assets/img/q5/a2.png';
import A3 from 'src/assets/img/q5/a3.png';
import A4 from 'src/assets/img/q5/a4.png';
import BG1 from 'src/assets/img/q5/bg1.png';
import BG2 from 'src/assets/img/q5/bg2.png';
import BG3 from 'src/assets/img/q5/bg3.png';
import BG4 from 'src/assets/img/q5/bg4.png';
import Castle1 from 'src/assets/img/q5/c1.png';
import Castle2 from 'src/assets/img/q5/c2.png';
import Castle3 from 'src/assets/img/q5/c3.png';
import Castle4 from 'src/assets/img/q5/c4.png';
import CloudLeft from 'src/assets/img/q5/cloud-l.png';
import CloudRight from 'src/assets/img/q5/cloud-r.png';
import Ghost from 'src/assets/img/q6/ghost.gif';
import Q7A1 from 'src/assets/img/q7/a1.png';
import Q7A2 from 'src/assets/img/q7/a2.png';
import Q7A3 from 'src/assets/img/q7/a3.png';
import Q7A4 from 'src/assets/img/q7/a4.png';
import Q7BG from 'src/assets/img/q7/bg.jpg';
import Q8BG01 from 'src/assets/img/q8/01.png';
import P8BG02 from 'src/assets/img/q8/02.png';
import P8BG03 from 'src/assets/img/q8/03.png';
import P8BG04 from 'src/assets/img/q8/04.png';
import Candle from 'src/assets/img/q9/candle.gif';
import Bear01 from 'src/assets/img/result/bear1.png';
import Bear02 from 'src/assets/img/result/bear2.png';
import Bear03 from 'src/assets/img/result/bear3.png';
import Bear04 from 'src/assets/img/result/bear4.png';
import CircleImg from 'src/assets/img/result/circle.png';
import FooterImg from 'src/assets/img/result/footer.png';
import IconSnow from 'src/assets/img/result/icon-snow.png';
import ResultB1 from 'src/assets/img/result/result-b1.jpg';
import ResultB2 from 'src/assets/img/result/result-b2.jpg';
import ResultB3 from 'src/assets/img/result/result-b3.jpg';
import ResultB4 from 'src/assets/img/result/result-b4.jpg';
import ResultWord from 'src/assets/img/result/result-word.jpg';
import Result01 from 'src/assets/img/result/result1.jpg';
import Result02 from 'src/assets/img/result/result2.jpg';
import Result03 from 'src/assets/img/result/result3.jpg';
import Result04 from 'src/assets/img/result/result4.jpg';
import TipsHoldImg from 'src/assets/img/result/tips-hold.png';
import TitleTextImg from 'src/assets/img/result/title-text.png';
import XmasBGMusic from 'src/assets/music/XmasBG.mp3';

// 匯入所有圖片
// 將所有圖片放入陣列
const ImageSources = [
  DreamJob,
  StartBtn,
  TopTitleImg,
  BearTree,
  Q1Cloud,
  Q1Title,
  Car,
  A21,
  A22,
  A23,
  A24,
  A31,
  A32,
  A33,
  A34,
  Deer1,
  Deer2,
  Deer3,
  Deer4,
  A1,
  A2,
  A3,
  A4,
  DeerHead,
  DeerIcon,
  Q2Vector,
  BG1,
  BG2,
  BG3,
  BG4,
  Castle1,
  Castle2,
  Castle3,
  Castle4,
  Ghost,
  P8BG02,
  P8BG03,
  P8BG04,
  Candle,
  TouchImg,
  Bear01,
  Bear02,
  Bear03,
  Bear04,
  CircleImg,
  FooterImg,
  IconSnow,
  ResultB1,
  ResultB2,
  ResultB3,
  ResultB4,
  ResultWord,
  Result01,
  Result02,
  Result03,
  Result04,
  TipsHoldImg,
  TitleTextImg,
  IconLoading,
  IconMusicOff,
  IconMusic,
  MusicCloud,
  MusicImg,
  QRCode,
  Title,
  CloudLeft,
  CloudRight,
  XmasBGMusic,
  BoWenImg,
  BtnCapture,
  F1Img,
  F2Img,
  F3Img,
  F4Img,
  IconReturn,
  IconSave,
  Vector,
  CheerIcon2,
  CheerIcon4,
  CheerIcon3,
  CheerIcon1,
  CheerBG1,
  CheerBG2,
  CheerBG3,
  CheerBG4,
  Gif2,
  Gif4,
  Gif3,
  Gif1,
  Q8BG01,
  Q7BG,
  Q7A1,
  Q7A2,
  Q7A3,
  Q7A4,
]

export default ImageSources;
