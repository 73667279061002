import './css/q6.scss';

import React from 'react';
import Ghost from 'src/assets/img/q6/ghost.gif';

const AnswerItem = ({ isActive, onClick, children }) => {
  return (
    <div className={`answer-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <p>{children}</p>
    </div>
  );
};

const Q6 = (props) => {

  return (
    <div className="q6-container mt-50">
      <p className="quiz-p">
        你注意到城堡前有個身影在向你揮手，<br />
        你覺得會是什麼動物呢？
      </p>
      <div className="ghost-box">
        <img src={Ghost} alt="Ghost" />
      </div>
      <div className="answer-container">
        <AnswerItem isActive={props.answer === 1} onClick={() => props.pickAnswer(1)}>
          穿著燕尾服的紳士企鵝
        </AnswerItem>
        <AnswerItem isActive={props.answer === 2} onClick={() => props.pickAnswer(2)}>
          戴著耶誕帽的淘氣貓咪
        </AnswerItem>
        <AnswerItem isActive={props.answer === 3} onClick={() => props.pickAnswer(3)}>
          抱著禮物盒的北極熊寶寶
        </AnswerItem>
        <AnswerItem isActive={props.answer === 4} onClick={() => props.pickAnswer(4)}>
          脖子上掛著鈴鐺的綿羊
        </AnswerItem>
      </div>
    </div>
  );
}

export default Q6;
