import './css/q10.scss';

import React, { useState } from 'react';
import TouchLight from 'src/assets/img/q10/touch-light.gif';
import TouchImg from 'src/assets/img/q10/touch.gif';
import Vector from 'src/assets/img/q10/vector.png';

const Q10 = (props) => {
  const [isTouch, setIsTouch] = useState(false);

  const handleResult = () => {
    setIsTouch(true);

    // GIF 重播
    const gifReplay = document.getElementById('gifReplay');
    gifReplay.src = '';

    setTimeout(() => {
      gifReplay.src = `${TouchLight}?${new Date().getTime()}`
    }, 100);

    setTimeout(() => {
      props.allQuestionsAnswered();
    }, 1000);
  }

  return (
    <div className="q10-container mt-50">
      <p className="quiz-p">
        點擊星星啟動耶誕魔法！ <br />
        你的天賦將點亮新北歡樂耶誕城， <br />
        讓祝福能量閃耀整個城市！
      </p>
      <div className="light-box" onClick={() => handleResult()}>
        <img src={TouchImg} alt="Light" className={`touch-img ${isTouch === true ? 'active' : ''}`} />
        <img id="gifReplay" src={TouchLight} alt="Light" className={`touch-light ${isTouch === true ? 'active' : ''}`} />
      </div>
      <div className={`vector-box ${isTouch === true ? 'active' : ''}`}>
        <img src={Vector} alt="Vector" />
      </div>

    </div>
  );
}

export default Q10;
