import './css/q7.scss';

import React from 'react';
import Q7A1 from 'src/assets/img/q7/a1.png';
import Q7A2 from 'src/assets/img/q7/a2.png';
import Q7A3 from 'src/assets/img/q7/a3.png';
import Q7A4 from 'src/assets/img/q7/a4.png';

const Q7 = (props) => {

  return (
    <div className="q7-container mt-50">
      <p className="quiz-p">
        抵達新北歡樂耶誕城，廣場中間有一<br />
        棵巨大的耶誕樹，琳瑯滿目的裝飾，<br />
        你最喜歡什麼？
      </p>
      <div className="answer-container">
        <div className="answer-item" onClick={() => props.pickAnswer(1)}>
          <p>金色鈴鐺</p>
          <img src={Q7A1} alt="Q7 Answer Item" />
        </div>

        <div className="answer-item" onClick={() => props.pickAnswer(2)}>
          <p>魔法刻印水晶</p>
          <img src={Q7A2} alt="Q7 Answer Item" />
        </div>
        <div className="answer-item" onClick={() => props.pickAnswer(3)}>
          <p>橡實吊飾</p>
          <img src={Q7A3} alt="Q7 Answer Item" />
        </div>
        <div className="answer-item" onClick={() => props.pickAnswer(4)}>
          <p>心型紅寶石</p>
          <img src={Q7A4} alt="Q7 Answer Item" />
        </div>
      </div>
    </div>
  );
}

export default Q7;
