import './css/result.scss';

import domtoimage from 'dom-to-image';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconLoading from 'src/assets/img/icon-loading.png';
import Bear01 from 'src/assets/img/result/bear1.png';
import Bear02 from 'src/assets/img/result/bear2.png';
import Bear03 from 'src/assets/img/result/bear3.png';
import Bear04 from 'src/assets/img/result/bear4.png';
import Castle1 from 'src/assets/img/result/castle1.png';
import Castle2 from 'src/assets/img/result/castle2.png';
import Castle3 from 'src/assets/img/result/castle3.png';
import Castle4 from 'src/assets/img/result/castle4.png';
import CircleImg from 'src/assets/img/result/circle.png';
import FooterImg from 'src/assets/img/result/footer.png';
import IconSnow from 'src/assets/img/result/icon-snow.png';
import ResultB1 from 'src/assets/img/result/result-b1.jpg';
import ResultB2 from 'src/assets/img/result/result-b2.jpg';
import ResultB3 from 'src/assets/img/result/result-b3.jpg';
import ResultB4 from 'src/assets/img/result/result-b4.jpg';
import ResultWord from 'src/assets/img/result/result-word.jpg';
import Result01 from 'src/assets/img/result/result1.jpg';
import Result02 from 'src/assets/img/result/result2.jpg';
import Result03 from 'src/assets/img/result/result3.jpg';
import Result04 from 'src/assets/img/result/result4.jpg';
import ScrollImg from 'src/assets/img/result/scroll.png';
import TipsHoldImg from 'src/assets/img/result/tips-hold.png';
import TitleTextImg from 'src/assets/img/result/title-text.png';

const PageResult = () => {
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState(''); // 玩家名稱
  const [resultBearTitle, setResultBearTitle] = useState('');
  const [resultCastleTitle, setResultCastleTitle] = useState('');
  const [resultImg, setResultImg] = useState(Result01);
  const [resultBottom, setResultBottom] = useState('');
  const [bearImg, setBearImg] = useState('');
  const [resultCastle, setResultCastle] = useState('');
  const [randomBlessText, setRandomBlessText] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 取得 Query String
    // 會有對應的職業：'SecretMage', 'ElementalExplorer', 'SoulHealer', 'HolyShield'
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const result = urlParams.get('result');
    setPlayerName(name);
    handleResultImg(result);

    // 如果result 沒有以上四個職業，就導回首頁
    if (result !== 'SecretMage' && result !== 'ElementalExplorer' && result !== 'SoulHealer' && result !== 'HolyShield') {
      window.location.href = '/';
    }

    randomBlessingText();

    // if #resultImg is loaded, renderResultCanvas
    const resultImg = document.getElementById('resultImg');
    if (resultImg.complete) {
      renderResultCanvas();
    } else {
      resultImg.onload = () => {
        renderResultCanvas();
      };
    }

    // class .scroll-img 如果往下滾動，就隱藏。如果回到最上面，就顯示
    // const mainContainer = document.querySelector('.container');
    // const scrollImg = document.querySelector('.scroll-img');
    // mainContainer.addEventListener('scroll', (e) => {
    //   if (mainContainer.scrollTop > 0) {
    //     scrollImg.style.display = 'none';
    //   } else {
    //     scrollImg.style.display = 'block';
    //   }
    // });
  }, []);

  // 隨機固定的四個祝福文字
  function randomBlessingText() {
    const blessTexts = [
      '2025 你將被幸福包圍！',
      '2025 好運擋都擋不住！',
      '2025 好運悄悄降臨，快樂不期而遇！',
      '2025 好運來敲門，幸福滿滿！',
      '2025 走路有風，好運跟著走！',
      '2025 財運旺旺，收穫滿滿！',
      '2025 心想事成，願望兌現！',
      '2025 身邊充滿了正能量！',
      '2025 人生開掛，事業、愛情都得意！',
      '2025 冒險滿滿，收穫無限！',
      '2025 挑戰多多，但每一步都有成長！',
      '2025 朋友多，歡樂多，煩惱無影蹤！',
      '2025 成功是你的新日常！',
      '2025 快樂加倍，煩惱減半！',
      '2025 宇宙都在為你加油！',
      '2025 在生活中找到屬於你的平靜。',
      '2025 工作與生活達到完美平衡！',
      '2025 一切都朝著好的方向發展！',
      '2025 你將擁有時間做自己熱愛的事！',
      '2025 你的旅遊清單將一一實現！',
      '2025 你將成為更好的自己！',
      '2025 健康與快樂將一直陪伴你左右！',
      '2025 無論做什麼，都能找到自己的節奏！',
      '2025 糾結消失，更多自在！',
      '2025 難題都會迎刃而解！',
      '2025 你的努力將有豐盛的回報！',
      '2025 你將有力量突破難關！',
      '2025 人生升級，新版本的你更強大！',
      '2025 想休息就能放鬆，想拼就能衝！',
      '2025 身體棒棒，精神飽滿！'
    ];

    const randomIndex = Math.floor(Math.random() * blessTexts.length);
    setRandomBlessText(blessTexts[randomIndex]);
  }

  // 雪花飄落效果
  useEffect(() => {
    const totalSnowflakes = 180;
    const container = document.getElementById('snow-container');
    const containerHeight = document.querySelector('.result-container');
    const windowHeight = window.innerHeight;

    // 偵聽 class container 的 s'croll 事件 
    const mainContainer = document.querySelector('.container');
    mainContainer.addEventListener('scroll', (e) => {

      // console.log('mainContainer.scrollTop', mainContainer.scrollTop + windowHeight);
      // console.log('containerHeight', containerHeight.clientHeight);

      //mainContainer + windowHeight < containerHeight
      if (mainContainer.scrollTop + windowHeight <= containerHeight.clientHeight) {
        container.style.top = mainContainer.scrollTop + 'px';
      }
    });

    for (let i = 0; i < totalSnowflakes; i++) {
      const snow = document.createElement('div');
      snow.classList.add('snow');

      const randomXStart = Math.random() * 100 + 'vw';
      const randomXEnd = Math.random() * 100 + 'vw';
      const randomScale = Math.random() * 0.2 + 0.5;
      const randomOpacity = Math.random();
      const fallDuration = Math.random() * 30 + 10 + 's';
      const fallDelay = Math.random() * -50 + 's';

      snow.style.setProperty('--x-start', randomXStart);
      snow.style.setProperty('--x-end', randomXEnd);
      snow.style.setProperty('--scale', randomScale);
      snow.style.setProperty('--opacity', randomOpacity);
      snow.style.animationDuration = fallDuration;
      snow.style.animationDelay = fallDelay;

      container.appendChild(snow);
    }

    return () => {
      mainContainer.removeEventListener('scroll', () => { });
    }
  }, []);

  function handleResultImg(result) {
    switch (result) {
      case 'ElementalExplorer':
        setResultImg(Result01);
        setResultBottom(ResultB1);
        setBearImg(Bear01);
        setResultCastle(Castle1);
        setResultBearTitle('元素探險者');
        setResultCastleTitle('飛船城堡祝福魔法');

        break;
      case 'SecretMage':
        setResultImg(Result02);
        setResultBottom(ResultB2);
        setBearImg(Bear02);
        setResultCastle(Castle2);
        setResultBearTitle('祕法智者');
        setResultCastleTitle('玻璃屋城堡祝福魔法');
        break;
      case 'SoulHealer':
        setResultImg(Result03);
        setResultBottom(ResultB3);
        setBearImg(Bear03);
        setResultCastle(Castle3);
        setResultBearTitle('靈魂治療師');
        setResultCastleTitle('神殿城堡祝福魔法');
        break;
      case 'HolyShield':
        setResultImg(Result04);
        setResultBottom(ResultB4);
        setBearImg(Bear04);
        setResultCastle(Castle4);
        setResultBearTitle('聖盾守護者');
        setResultCastleTitle('木屋城堡祝福魔法');
        break;
      default:
        break;
    }
  }

  let browserName
  // Detect Device
  // TODO: 這邊要判斷是在哪個環境，如果是在 APP 裡面，就不要顯示分享按鈕
  function detectDevice() {
    const userAgent = window.navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "Edge";
    } else if (userAgent.match(/android/i)) {
      browserName = "Android";
    } else if (userAgent.match(/iphone/i)) {
      browserName = "iPhone";
    } else {
      browserName = "Unknown";
    }
  }

  function ifInApp() {
    const inapp = navigator.userAgent || navigator.vendor || window.opera;
    let android = inapp.indexOf('Android') > -1 || inapp.indexOf('Adr') > -1; // android
    if (android === false) {
      // iOS
      if (inapp.indexOf("FBAN") > -1 || inapp.indexOf("FBAV") > -1) {
        // Facebook App
      } else {
        // Browser
      }
    } else {
      // Android
    }
  }


  function renderResultCanvas() {

    if (resultImg) {
      // html2canvas(document.querySelector("#capture")).then(canvas => {
      //   console.log('canvas');
      //   // Create a download link
      //   const link = document.createElement('img')
      //   link.crossOrigin = "";
      //   link.src = canvas.toDataURL('image/jpeg');
      //   const resultCapture = document.getElementById("result-canvas");

      //   if (resultCapture) {
      //     resultCapture.appendChild(link);
      //     link.setAttribute('id', 'canvasResult');
      //   }
      //   ifInApp();
      // });

      const scale = 2;
      const domNode = document.getElementById('capture');

      domtoimage.toPng(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      })

      setTimeout(() => {
        domtoimage.toPng(domNode, {
          width: domNode.clientWidth * scale,
          height: domNode.clientHeight * scale,
          style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left'
          }
        })
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            img.id = 'canvasResult';
            var resultCapture = document.getElementById("result-canvas");
            resultCapture.innerHTML = '';

            if (resultCapture) {
              resultCapture.appendChild(img);
            }
          })
        setLoading(false);
      }, 5000);
    }
  }

  function handleDownloadResult() {
    // let canvas = document.getElementById("result-canvas");
    // html2canvas(canvas)
    //   .then((canvas) => {
    //     // Create a download link
    //     const link = document.createElement('a');
    //     link.crossOrigin = "";
    //     link.href = canvas.toDataURL('image/png');
    //     link.download = 'merry-christmas!.jpg';
    //     link.click();
    //   })
    //   .catch((error) => {
    //     console.error('Screenshot failed:', error);
    //   });

    const scale = 2;
    const domNode = document.getElementById('capture');


    domtoimage.toPng(domNode, {
      width: domNode.clientWidth * scale,
      height: domNode.clientHeight * scale,
      style: {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left'
      }
    })

    setTimeout(() => {
      domtoimage.toPng(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      })
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.href = dataUrl;
          link.download = '2024christmas';
          link.click();
        });
    }, 2000);
  }

  // 判斷是否為手機
  function isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  }

  // 分享連結
  function shareLink() {
    if (navigator.userAgent.indexOf('Mac OS X') !== -1 && !isMobile()) {
      // TODO: 這邊要改成正確的網址
      var fixedTextToCopy = "https://www.2024christmasland.tw/";

      var textArea = document.createElement('textarea');
      textArea.value = fixedTextToCopy;
      document.body.appendChild(textArea);
      textArea.select();

      if (document.execCommand('copy')) {
        const btnShare = document.getElementById('btnShare');
        btnShare.classList.add('copied');

        var temp = setInterval(function () {
          btnShare.classList.remove('copied');
          clearInterval(temp);
        }, 600);
      } else {
        console.info('document.execCommand went wrong…')
      }

      // remove the textarea after copy
      document.body.removeChild(textArea);

    } else {
      if (navigator.share) {

        navigator.share({
          title: '2024 新北歡樂耶誕城',
          text: '你的夢幻天職是什麼？一起完成心理測驗蒐集祝福能量，點亮 2024 新北歡樂耶誕城！',
          url: 'https://www.2024christmasland.tw/',
        })
          .then(() => console.log('Share success'))
          .catch((error) => console.log('Error', error));
      }
    }
  }

  function handleNavigateAR() {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const result = urlParams.get('result');
    setPlayerName(name);
    handleResultImg(result);

    navigate(`/ar?name=${name}&result=${result}`);
  }

  function handleNavigateMagic() {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const result = urlParams.get('result');
    setPlayerName(name);
    handleResultImg(result);

    navigate(`/magic?name=${name}&result=${result}`);
  }

  return (
    <div>

      <div className={`result-container ${loading ? 'loading' : ''}`}>
        {/* 雪花飄落 */}
        <div id="snow-container"></div>

        {/* 結果 */}
        <div className="result-img-box" id="result-canvas">
          {/* <img src={resultImg} alt="Result" id="resultImg" />
          <div className="result-word-box">
            <img src={ResultWord} alt="Result Word" className="result-word" />
            <p>{randomBlessText}</p>
          </div>
          <p className="player-name">{playerName}</p> */}
        </div>
        <div id="capture" className="result-image">
          <img id="resultImg" src={resultImg} alt="Result" />
          <div className="result-word-box">
            <img src={ResultWord} alt="Result Word" className="result-word" />
            <p>{randomBlessText}</p>
          </div>
          <p className="player-name">{playerName}</p>
        </div>


        <div className="result-bottom-box">
          <img src={resultBottom} alt="Result Bottom" className="result-bottom" />
          <img src={ScrollImg} alt="Scroll" className="scroll-img" />
        </div>
        <div className="result-text-box">
          <div className="result-content">
            <img src={TipsHoldImg} alt="Tips" className="tips-hold" />
            <button className="download-btn" onClick={() => { handleDownloadResult() }}>
              點擊下載圖片
            </button>
            <img src={TitleTextImg} alt="Title" className="title-text" />

            <div className="result-element">
              <p className="result-title">
                <img src={IconSnow} alt="Snow" className="icon-snow-left" />
                變裝{resultBearTitle}
                <img src={IconSnow} alt="Snow" className="icon-snow-right" />
              </p>
              <p className="result-p">上傳且通過審核後，將在主舞台互動展演時段演出，在下一場互動秀找找自己吧！</p>
              <div className="result-bear">
                <img src={bearImg} alt="Bear" />
              </div>
              <button className="start-ar-btn" onClick={() => { handleNavigateAR() }}>
                開始 AR 變裝
              </button>
            </div>

            <div className="result-element">
              <p className="result-title">
                <img src={IconSnow} alt="Snow" className="icon-snow-left" />
                {resultCastleTitle}
                <img src={IconSnow} alt="Snow" className="icon-snow-right" />
              </p>
              <p className="result-p">於主舞台互動展演時段，用{resultBearTitle}的能量為城堡施放祝福！</p>
              <div className="result-castle">
                <img src={resultCastle} alt="Castle" />
              </div>
              <button className="start-magic-btn" onClick={() => { handleNavigateMagic() }}>
                施展祝福魔法
              </button>
            </div>

            <div className="result-share">
              <span onClick={() => { navigate('/') }}>
                <p>再測驗一次</p>
                <img src={CircleImg} alt="Circle" />
              </span>
              <span onClick={() => { shareLink() }} id="btnShare">
                <p>分享給朋友</p>
                <img src={CircleImg} alt="Circle" />
              </span>
            </div>

            <img src={FooterImg} alt="Footer" className="footer-img" />
          </div>
        </div>
      </div>

      {
        !loading ?
          null :
          <div className="loading-bear">
            <img src={IconLoading} alt="Loading Bear" />
            <p>載入中...</p>
          </div>
      }

    </div>
  );
}

export default PageResult;
