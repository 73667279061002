import React from 'react';
import IconStar from 'src/assets/img/icon-star.png';
import TopTitleImg from 'src/assets/img/top-title.png';

const HeaderQ = (props) => {
  const totalQ = 10;

  return (
    <header className="qa-header">
      <img src={TopTitleImg} alt="Top Title" className="top-title" />

      <div className="progress-bar">
        <div className="progress-bar-inner">
          {
            Array.from({ length: totalQ }, (v, i) => (
              <span key={i} className={i === 0 ? 'active' : ''}></span>
            ))
          }
        </div>
        {/* The star will be shown when the question is answered , 100% / 9 = 11.11% */}
        <img
          src={IconStar}
          alt="Star"
          style={{ left: `${((props.currentQ - 1) * 11.11)}%` }}
          className="icon-star"
        />
      </div>
    </header>
  );
}

export default HeaderQ;
