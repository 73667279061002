import './css/q3.scss';

import React, { useState } from 'react';
import IconChoose from 'src/assets/img/icon-choose.png';
import A1 from 'src/assets/img/q3/a1.png';
import A2 from 'src/assets/img/q3/a2.png';
import A3 from 'src/assets/img/q3/a3.png';
import A4 from 'src/assets/img/q3/a4.png';
import Deer1 from 'src/assets/img/q3/deer1.png';
import Deer2 from 'src/assets/img/q3/deer2.png';
import Deer3 from 'src/assets/img/q3/deer3.png';
import Deer4 from 'src/assets/img/q3/deer4.png';

const PickItem = ({ imgSrc, isActive, onClick, iconChooseSrc, altText }) => {
  return (
    <div className={`pick-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <img src={imgSrc} alt={altText} />
      {isActive && <img src={iconChooseSrc} alt="Choose Icon" className="icon-choose" />}
    </div>
  );
};

const Q3 = (props) => {
  const [pickAnswer, setPickAnswer] = useState(1);
  const [deerType, setDeerType] = useState(Deer1);
  const [answerText, setAnswerText] = useState('喜歡到處探險的紅鼻子馴鹿');

  const chooseAnswer = (num) => {
    switch (num) {
      case 1:
        setPickAnswer(1);
        setDeerType(Deer1);
        setAnswerText('喜歡到處探險的紅鼻子馴鹿');
        break;
      case 2:
        setPickAnswer(2);
        setDeerType(Deer2);
        setAnswerText('散發著療癒能量的純白優雅馴鹿');
        break;
      case 3:
        setPickAnswer(3);
        setDeerType(Deer3);
        setAnswerText('快到讓你懷疑人生的跑跑馴鹿');
        break;
      case 4:
        setPickAnswer(4);
        setDeerType(Deer4);
        setAnswerText('經驗老道的馴鹿里長伯');
        break;
      default:
        break;
    }
  }

  const confirmAnswer = () => {
    props.pickAnswer(pickAnswer);
  }

  return (
    <div className="q3-container mt-50">
      <p className="quiz-p">
        馴鹿們吃飽喝足，準備出發！<br />你想和哪隻馴鹿一起飛翔？
      </p>
      <div className="deer-type">
        <img src={deerType} alt="Deers" />
      </div>
      <p className="answer-text">
        {answerText}
      </p>
      <div className="pick-container">
        {
          Array.from({ length: 4 }, (v, i) => (
            <PickItem
              key={i}
              imgSrc={i === 0 ? A1 : i === 1 ? A2 : i === 2 ? A3 : A4}
              isActive={deerType === (i === 0 ? Deer1 : i === 1 ? Deer2 : i === 2 ? Deer3 : Deer4)}
              onClick={() => { chooseAnswer(i + 1) }}
              iconChooseSrc={IconChoose}
              altText={`Pick Item ${i + 1}`}
            />
          ))
        }
      </div>

      <button onClick={confirmAnswer} className="confirm-btn">就決定是你了</button>
    </div>
  );
}

export default Q3;
