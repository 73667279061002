import './css/q9.scss';

import React from 'react';
import Candle from 'src/assets/img/q9/candle.gif';

const AnswerItem = ({ isActive, onClick, children }) => {
  return (
    <div className={`answer-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <p>{children}</p>
    </div>
  );
};

const Q6 = (props) => {

  return (
    <div className="q9-container mt-50">
      <p className="quiz-p">
        如果可以選擇實現一個耶誕願望，<br />
        你希望...
      </p>
      <div className="candle-box">
        <img src={Candle} alt="Ghost" />
      </div>
      <div className="answer-container">
        <AnswerItem isActive={props.answer === 1} onClick={() => props.pickAnswer(1)}>
          擁有一道任意門，想去哪就去哪
        </AnswerItem>
        <AnswerItem isActive={props.answer === 2} onClick={() => props.pickAnswer(2)}>
          習得讀心術，了解別人內心的想法
        </AnswerItem>
        <AnswerItem isActive={props.answer === 3} onClick={() => props.pickAnswer(3)}>
          獲得超勇身體，永遠不會累
        </AnswerItem>
        <AnswerItem isActive={props.answer === 4} onClick={() => props.pickAnswer(4)}>
          成為時空旅人，自由穿梭過去未來
        </AnswerItem>
      </div>
    </div>
  );
}

export default Q6;
