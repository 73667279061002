import './css/ar.scss';

import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import BoWenImg from 'src/assets/img/ar/bowen.png';
import BtnAgain from 'src/assets/img/ar/btn-again.png';
import BtnCapture from 'src/assets/img/ar/btn-capture.png';
import F1Img from 'src/assets/img/ar/f1.png';
import F2Img from 'src/assets/img/ar/f2.png';
import F3Img from 'src/assets/img/ar/f3.png';
import F4Img from 'src/assets/img/ar/f4.png';
import FaceHintImg from 'src/assets/img/ar/face-hint.png';
import IconCheck from 'src/assets/img/ar/icon-check.png';
import IconReturn from 'src/assets/img/ar/icon-return.png';
import IconSave from 'src/assets/img/ar/icon-save.png';
import IconLoading from 'src/assets/img/icon-loading.png';
import TopTitleImg from 'src/assets/img/main/top-title.png';
import Vector from 'src/assets/img/q10/vector.png';

const PageAR = () => {
  const [loading, setLoading] = useState(true);
  const [playerName, setPlayerName] = useState('');
  const [arCover, setARCover] = useState('');
  const [isStartAR, setIsStartAR] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [captureDone, setCaptureDone] = useState(false);
  const [photoWebGL, setPhotoWebGL] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [timeShow, setTimeShow] = useState('');

  const [handleFull, setHandleFull] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);
  // const [saveImageLoading, setSaveImageLoading] = useState(false);

  useEffect(() => {
    let camp = 0;

    function handleResultImg(result) {
      switch (result) {
        case 'ElementalExplorer':
          camp = 0;
          setARCover(F1Img);
          break;
        case 'SecretMage':
          camp = 1;
          setARCover(F2Img);
          break;
        case 'SoulHealer':
          camp = 2;
          setARCover(F3Img);
          break;
        case 'HolyShield':
          camp = 3;
          setARCover(F4Img);
          break;
        default:
          break;
      }
    }

    // 去到 .container 的最上方
    const container = document.querySelector('.container');
    container.scrollTop = 0;

    // 取得 Query String
    // 會有對應的職業：'SecretMage', 'ElementalExplorer', 'SoulHealer', 'HolyShield'
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const result = urlParams.get('result');
    setPlayerName(name);
    handleResultImg(result);

    // 如果result 沒有以上四個職業，就導回首頁
    if (result !== 'SecretMage' && result !== 'ElementalExplorer' && result !== 'SoulHealer' && result !== 'HolyShield') {
      window.location.href = '/';
    }

    // unityWEBGL 加載完畢
    const unityWEBGL = document.getElementById('unityWEBGL');
    unityWEBGL.onload = () => {

      unityWEBGL.contentWindow.document.addEventListener("unityWebGL_GetInitData", (e) => {
        setIsStartAR(true);

        // TODO: 要改去等 Unity AR 完成後再關閉 loading
        setTimeout(() => {
          setLoading(false);

          // 傳送給 Unity AR, 陣營
          console.log("setWebArAccessories");
          unityWEBGL.contentWindow.setWebArAccessories(camp);


          // 接收unity傳來的是否捕捉到臉部
          unityWEBGL.contentWindow.document.addEventListener("unityWebGL_SendFaceDetect", (e) => {
            if (e.detail && e.detail.isDetectedFace === 1) {
              // 有偵測到臉部
              setShowHint(false);
            } else {
              // 沒有偵測到臉部
              setShowHint(true);
            }
          });

        }, 2000);
      });
    }
  }, []);

  const handleCapture = async () => {
    if (captureDone) return;
    const unityWEBGL = document.getElementById('unityWEBGL');
    const photoWEBGL = await unityWEBGL.contentWindow.takeScreenshot();

    console.log('photoWEBGL:', photoWEBGL);
    setPhotoWebGL(photoWEBGL);
    setCaptureDone(true);
  }

  const handleCaptureAgain = () => {
    const unityWEBGL = document.getElementById('unityWEBGL');
    unityWEBGL.contentWindow.takeAgain();
    setPhotoWebGL('');
    setCaptureDone(false);
  }

  function resizeBase64Image(base64Str, maxSize) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Str;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const newBase64 = canvas.toDataURL('image/jpeg', 0.8);
        resolve(newBase64);
      };

      img.onerror = function () {
        reject(new Error('Failed to load the image.'));
      };
    });
  }

  /*
  * 上傳至大螢幕
  * return ok,result, showUnixTimestamp
  */
  const handleUploadToServer = async () => {
    setUploadLoading(true);

    const unityContainer = document.getElementById('photoBox');

    let isUpload = false;
    const createImgAndUpload = new Promise(r => {
      html2canvas(unityContainer, { scale: 2 }).then(async canvas => {
        const photo = canvas.toDataURL('image/png');
        const compressPhoto = await resizeBase64Image(photo, 512);
        const unityWEBGL = document.getElementById('unityWEBGL');
        isUpload = await unityWEBGL.contentWindow.uploadToServer(compressPhoto);
        r();
      });
    });

    await Promise.all([createImgAndUpload]);

    if (isUpload) {
      // 場次已滿
      if (isUpload.ok === false && isUpload.message === 'no session') {
        // alert('場次已滿，請稍後再試');
        setUploadLoading(false);
        setHandleFull(true);
        return;
      }

      // 上傳成功
      if (isUpload.ok === true) {
        setUploadSuccess(true);

        // 設定顯示時間
        const showTime = new Date(isUpload.showUnixTimestamp * 1000);
        const year = showTime.getFullYear();
        const month = showTime.getMonth() + 1;
        const date = showTime.getDate();
        const hour = handleHour(showTime.getHours());
        const minute = handleMinute(showTime.getMinutes());
        const showTimeStr = `${year}/${month}/${date} ${hour}:${minute}`;
        setTimeShow(showTimeStr);

        setUploadLoading(false);
      }
    }
  }

  // 處理分鐘
  const handleMinute = (minute) => {
    if (minute < 10) {
      return `0${minute}`;
    } else {
      return minute;
    }
  }

  // 處理小時
  const handleHour = (hour) => {
    if (hour < 10) {
      return `0${hour}`;
    } else {
      return hour;
    }
  }

  // 把id unity-container 的內容轉成圖片
  const handleSaveImage = async () => {
    const unityContainer = document.getElementById('photoBox');

    setTimeout(() => {
      html2canvas(unityContainer, { scale: 2 }).then(canvas => {
        const link = document.createElement('a');
        link.download = '2024christmas.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });

    }, 1000);
  }

  return (
    <>
      <div className={`ar-bg ${loading ? 'loading' : ''}`}>
        {
          captureDone ?
            null :
            <img src={BoWenImg} alt="BoWen" className="bowen" />
        }
        <img src={TopTitleImg} alt="Top Title" className="top-title" />

        {/* 返回按鍵 */}
        {
          uploadLoading ?
            null :
            <button className="btn-return" onClick={() => { window.history.back() }}>
              <img src={IconReturn} alt="Return" />
            </button>
        }

        {/* 儲存照片 */}
        {
          (!captureDone || uploadLoading || handleFull) ? null
            :
            <button className="btn-save" onClick={() => { handleSaveImage() }}>
              <img src={IconSave} alt="Save" />
              <p>儲存圖片</p>
            </button>
        }

        <div className="ar-content">
          {
            (!showHint || captureDone) ?
              <p class="empty-p">-</p> :
              <p>請將你的正臉和小客熊對齊</p>
          }
          <div id="unity-container">
            {/* Iframe public/index.html */}
            <iframe id="unityWEBGL" title="unity" src={`${process.env.PUBLIC_URL}/webgl/index.html`} className="unity-mobile" />

            {/* 儲存圖片用的 */}
            {/* {
              photoWebGL !== '' && saveImageLoading ? */}
            <div className="photo-box" id="photoBox">
              <img src={photoWebGL} alt="" />
              <img src={arCover} alt="AR Cover" className="ar-cover" />
              {
                (!showHint || captureDone) ? null : <img src={FaceHintImg} alt="Face Hint" className="face-hint" />
              }

            </div>
            : null
            {/* } */}
          </div>
        </div>

        <div className="ar-action-container">
          <div className="ar-action">
            <div className="ar-action-btns">
              {
                handleFull ?
                  <div className="full-box">
                    <p>感謝您的參與，今日上傳名額已滿。</p>
                  </div>
                  :
                  <>
                    {
                      uploadSuccess ?
                        <div className="time-show">
                          <p>預計顯示時間</p>
                          <p>{timeShow}</p>
                        </div>
                        :
                        <>
                          {
                            (captureDone && !uploadSuccess) ?
                              <button className="btn-again" onClick={() => { handleCaptureAgain() }}>
                                <img src={BtnAgain} alt="Again" />
                              </button> :
                              <>
                                <button className="btn-ar" onClick={() => { handleCapture() }} style={{ opacity: captureDone ? 0.5 : 1 }}>
                                  <img src={BtnCapture} alt="Capture" />
                                </button>
                                <p>點擊拍照</p>
                              </>
                          }
                        </>
                    }
                  </>
              }
              {
                !captureDone ?
                  <div className={`vector-box ${isStartAR === true ? 'active' : ''}`}>
                    <img src={Vector} alt="Vector" />
                  </div> : null
              }
            </div>
          </div>
          {
            (captureDone && !handleFull) ?
              <div className="ar-footer">
                {
                  uploadSuccess ?
                    <button className="upload-success">
                      上傳成功
                      <img src={IconCheck} alt="Check" />
                    </button>
                    :
                    <button onClick={() => { handleUploadToServer() }}>
                      上傳至大螢幕
                    </button>
                }

                <p>
                  *上傳且通過審核後，將在主舞台互動展演時段演出， 在下一場互動秀找找自己吧！
                </p>
              </div>
              : null
          }
          {/* Loading Spinner */}
          {
            uploadLoading ?
              <div className="loading-spinner-bg">
                <div className="spin-loader" />
              </div>
              : null
          }
        </div>
      </div>

      {
        loading ?
          <div className="loading-bear">
            <img src={IconLoading} alt="Loading Bear" />
            <p>載入中...</p>
          </div>
          : null
      }
    </>
  );
}

export default PageAR;
