import 'src/pages/css/home.scss';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DreamJob from 'src/assets/img/main/dream.png';
import StartBtn from 'src/assets/img/main/start-btn.png';
import TopTitleImg from 'src/assets/img/main/top-title.png';
import BearTree from 'src/assets/img/main/xmasTree.gif';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // 進來第一時間回到 class="container" 的最上方
    const mainContainer = document.querySelector('.container');
    mainContainer.scrollTop = 0;

    const totalSnowflakes = 180;
    const container = document.getElementById('snow-container');

    for (let i = 0; i < totalSnowflakes; i++) {
      const snow = document.createElement('div');
      snow.classList.add('snow');

      const randomXStart = Math.random() * 100 + 'vw';
      const randomXEnd = Math.random() * 100 + 'vw';
      const randomScale = Math.random() * 0.2 + 0.5;
      const randomOpacity = Math.random();
      const fallDuration = Math.random() * 20 + 10 + 's';
      const fallDelay = Math.random() * -50 + 's';

      snow.style.setProperty('--x-start', randomXStart);
      snow.style.setProperty('--x-end', randomXEnd);
      snow.style.setProperty('--scale', randomScale);
      snow.style.setProperty('--opacity', randomOpacity);
      snow.style.animationDuration = fallDuration;
      snow.style.animationDelay = fallDelay;

      container.appendChild(snow);
    }
  }, []);


  const startGame = () => {
    // const currentUrl = window.location.href;
    // if (!currentUrl.includes('?openExternalBrowser=1')) {
    //   const newUrl = currentUrl + '?openExternalBrowser=1';
    //   window.location.href = newUrl;
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 500);
    // } else {
    //   setTimeout(() => {
    //     navigate('/quiz');
    //     window.scrollTo(0, 0);
    //   }, 500);
    // }

    navigate('/quiz');
  }

  return (
    <div className="home-bg">
      <img src={TopTitleImg} alt="Top Title" className="top-title" />
      <div className="home-content">
        <img src={BearTree} alt="Bear Tree" className="bear-tree" />
        {/* <h1>你的夢幻天職是什麼？</h1> */}
        <div className="dream-title">
          <img src={DreamJob} alt="Dream Job" className="dream-job-behind" />
          <img src={DreamJob} alt="Dream Job" className="dream-job-front" />
        </div>

        <p>蒐集祝福能量，點亮新北歡樂耶誕城！</p>

        <div id="snow-container"></div>
        <button className="start-btn" onClick={() => { startGame() }}>
          <img src={StartBtn} alt="Start Button" />
        </button>
      </div>
    </div>
  );
}

export default Home;
