import './css/q2.scss';

import React from 'react';
import A1 from 'src/assets/img/q2/a1.png';
import A2 from 'src/assets/img/q2/a2.png';
import A3 from 'src/assets/img/q2/a3.png';
import A4 from 'src/assets/img/q2/a4.png';
import DeerHead from 'src/assets/img/q2/deer-head.png';
import DeerIcon from 'src/assets/img/q2/deer-icon.png';
import Q2Vector from 'src/assets/img/q2/vector.png';

const Q2 = (props) => {
  const chooseAnswer = (num) => {
    props.pickAnswer(num);
  }

  return (
    <div className="q2-container mt-50">
      <p className="quiz-p">
        前往耶誕城前，先餵飽肚子餓的馴鹿吧！<br />你會準備什麼點心呢？
      </p>
      <div className="q2-quiz-box">
        <img src={Q2Vector} alt="Q2 Vector" className="q2-vector" />
        <div className="deer-deco">
          <img src={DeerHead} alt="Deer Head" className="deer-head" />
          <img src={DeerIcon} alt="Deer Icon" className="deer-icon" />
        </div>

        {/* Grid 2x2 */}
        <div className="q2-quiz-grid">
          <div className="q2-quiz-item" onClick={() => { chooseAnswer(1) }}>
            <img src={A1} alt="Q2 Quiz Item" />
            <p>歡樂彩虹拐杖糖</p>
          </div>
          <div className="q2-quiz-item" onClick={() => { chooseAnswer(2) }}>
            <img src={A2} alt="Q2 Quiz Item" />
            <p>金黃焦糖蘋果塔</p>
          </div>
          <div className="q2-quiz-item" onClick={() => { chooseAnswer(3) }}>
            <img src={A3} alt="Q2 Quiz Item" />
            <p>幸福心願魔法餅乾</p>
          </div>
          <div className="q2-quiz-item" onClick={() => { chooseAnswer(4) }}>
            <img src={A4} alt="Q2 Quiz Item" />
            <p>能量滿分薑餅人</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q2;
