import './css/magic.scss';

import React, { useEffect, useState } from 'react';
import IconReturn from 'src/assets/img/ar/icon-return.png';
import CheerIcon2 from 'src/assets/img/cheer/cheer-b2.png';
import CheerIcon4 from 'src/assets/img/cheer/cheer-g4.png';
import CheerIcon3 from 'src/assets/img/cheer/cheer-r3.png';
import CheerIcon1 from 'src/assets/img/cheer/cheer-y1.png';
import CheerBG1 from 'src/assets/img/cheer/cheer1.jpg';
import CheerBG2 from 'src/assets/img/cheer/cheer2.jpg';
import CheerBG3 from 'src/assets/img/cheer/cheer3.jpg';
import CheerBG4 from 'src/assets/img/cheer/cheer4.jpg';
import Gif2 from 'src/assets/img/cheer/effect-b2.gif';
import Gif4 from 'src/assets/img/cheer/effect-g4.gif';
import Gif3 from 'src/assets/img/cheer/effect-r3.gif';
import Gif1 from 'src/assets/img/cheer/effect-y1.gif';
import TopTitleImg from 'src/assets/img/main/top-title.png';

const PageMagic = () => {

  const [magicJob, setMagicJob] = useState('');
  const [magicBG, setMagicBG] = useState('');
  const [magicIcon, setMagicIcon] = useState('');
  const [magicGif, setMagicGif] = useState('');
  const borderStyle = {
    border: `1px solid ${magicIcon === CheerIcon1 ? '#F0D65D' :
      magicIcon === CheerIcon2 ? '#435DE9' :
        magicIcon === CheerIcon3 ? '#FA5D62' : '#12885B'}`
  };

  const magicTextStyle = {
    color: magicIcon === CheerIcon1 ? '#F0D65D' :
      magicIcon === CheerIcon2 ? '#435DE9' :
        magicIcon === CheerIcon3 ? '#FA5D62' : '#12885B'
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get('result');

    switch (result) {
      case 'ElementalExplorer':
        setMagicJob('元素探險者');
        setMagicBG(CheerBG1);
        setMagicIcon(CheerIcon1);
        break;
      case 'SecretMage':
        setMagicJob('祕法智者');
        setMagicBG(CheerBG2);
        setMagicIcon(CheerIcon2);
        break;
      case 'SoulHealer':
        setMagicJob('心靈治療師');
        setMagicBG(CheerBG3);
        setMagicIcon(CheerIcon3);
        break;
      case 'HolyShield':
        setMagicJob('聖盾守護者');
        setMagicBG(CheerBG4);
        setMagicIcon(CheerIcon4);
        break;
      default:
        break;
    }
  }, []);

  let lastEffectTime = 0; // 記錄最後一次觸發的時間

  const handleEffect = () => {
    const currentTime = new Date().getTime();

    // 檢查距離上次觸發是否已經超過 3 秒（3000 毫秒）
    if (currentTime - lastEffectTime < 2000) {
      return; // 如果沒有超過 3 秒，則不執行函數
    }

    // 更新最後觸發的時間
    lastEffectTime = currentTime;

    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get('result');

    let camp = 0;
    let newGif = ''; // 儲存新的 gif 路徑
    switch (result) {
      case 'ElementalExplorer':
        camp = 0;
        newGif = Gif1;
        break;
      case 'SecretMage':
        camp = 1;
        newGif = Gif2;
        break;
      case 'SoulHealer':
        camp = 2;
        newGif = Gif3;
        break;
      case 'HolyShield':
        camp = 3;
        newGif = Gif4;
        break;
      default:
        break;
    }

    // 如果新 GIF 與當前 GIF 不同才更新 src
    const gif = document.querySelector('.magic-gif');
    gif.src = ''; // 先清空 src，避免瀏覽器不重新載入
    if (gif.src !== newGif) {
      setTimeout(() => {
        gif.src = `${newGif}?${new Date().getTime()}`; // 防止緩存
      }, 100);
    }

    fetch(`https://us-central1-new-taipei-christmas-city-2024.cloudfunctions.net/CheerUp?camp=${camp}`)
      .then(data => {
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const preventRightClick = (e) => {
    e.preventDefault();
  };

  return (
    <div className="magic-bg" style={{ backgroundImage: `url(${magicBG})` }}>
      <img src={magicBG} alt="Magic BG" className="magic-bg-img" />
      <div className="magic-border" style={borderStyle} onContextMenu={preventRightClick} />
      <img src={magicIcon} alt="Magic Icon" className="magic-icon-img" onContextMenu={preventRightClick} />
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: '200px',
        background: 'transparent',
        cursor: 'pointer',
        zIndex: 9
      }}
        onClick={() => { handleEffect() }}
      />


      <img src={magicGif} alt="" className="magic-gif" />
      <img src={TopTitleImg} alt="Top Title" className="top-title" />


      <button className="btn-return" onClick={() => { window.history.back() }}>
        <img src={IconReturn} alt="Return" />
      </button>

      <div className="magic-content">
        <p>點擊施放<span style={magicTextStyle}>{magicJob}</span>祝福魔法</p>
      </div>
    </div>
  );
}

export default PageMagic;
