import './App.scss';

import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import IconLoading from 'src/assets/img/icon-loading.png';
import IconMusicOff from 'src/assets/img/icon-music-off.png';
import IconMusic from 'src/assets/img/icon-music.png';
import MusicCloud from 'src/assets/img/main/music-cloud.png';
import MusicImg from 'src/assets/img/main/music.png';
import QRCode from 'src/assets/img/main/QRcode.png';
import Title from 'src/assets/img/main/title.png';
import CloudLeft from 'src/assets/img/q5/cloud-l.png';
import CloudRight from 'src/assets/img/q5/cloud-r.png';
import Q7BG from 'src/assets/img/q7/bg.jpg';
import bgQ81 from 'src/assets/img/q8/01.png';
import bgQ82 from 'src/assets/img/q8/02.png';
import bgQ83 from 'src/assets/img/q8/03.png';
import bgQ84 from 'src/assets/img/q8/04.png';

import XmasBGMusic from './assets/music/XmasBG.mp3';
import ImagePreloader from './imageLoaded';
import imageSources from './imageSources';
import PageAR from './pages/ar';
import Home from './pages/home';
import PageMagic from './pages/magic';
import PageQuiz from './pages/quiz';
import PageResult from './pages/result';

const LineBrowserRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 檢查是否是 LINE 瀏覽器
    const isLineBrowser = /Line/i.test(userAgent);

    // 檢查 URL 是否已有 openExternalBrowser 參數
    const params = new URLSearchParams(location.search);
    const hasOpenExternalBrowser = params.has('openExternalBrowser');

    if (isLineBrowser && !hasOpenExternalBrowser) {
      // 如果是 LINE 瀏覽器且沒有 openExternalBrowser 參數，則加上
      params.set('openExternalBrowser', '1');

      // 使用 navigate 導向新的 URL
      navigate({ search: params.toString() }, { replace: true });
      window.location.reload();
    }
  }, [location, navigate]);

  return null; // 這個 component 只是用來處理重定向，不會渲染任何內容
};

const App = () => {
  const location = useLocation();
  const [musicOn, setMusicOn] = useState(true);
  const [closeMusicCloud, setCloseMusicCloud] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentQuiz, setCurrentQuiz] = useState(1);

  const [cloudOpen, setCloudOpen] = useState(false);
  const [showChristmasTree, setShowChristmasTree] = useState(false);
  const imagesQ8 = [bgQ81, bgQ82, bgQ83, bgQ84];
  const [currentImageIndexQ8, setCurrentImageIndexQ8] = useState(0);

  const [readyToShowResult, setReadyToShowResult] = useState(false);
  const [notYetReadyToShowResult, setNotYetReadyToShowResult] = useState(true);

  // 音樂開關
  const toggleMusic = () => {
    setMusicOn(!musicOn);
    const audio = document.getElementById('xm-music');
    if (musicOn) {
      audio.setAttribute('data-playing', 'false');
      audio.pause();
    } else {
      audio.setAttribute('data-playing', 'true');
      audio.play();
    }

    // 音量
    audio.volume = 0.2;
  };

  useEffect(() => {
    // 監聽網頁是否被隱藏
    const handleVisibilityChange = () => {
      const hidden = document.hidden;
      const audioPath = document.getElementById('xm-music');

      if (hidden && !audioPath.paused) {
        audioPath.pause();
      } else if (!hidden && audioPath.currentTime > 0) {

        // if audioPath data-playing is true, play music
        if (audioPath.getAttribute('data-playing') === 'true') {
          audioPath.play();
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  useEffect(() => {
    if (currentQuiz === 5) {
      setCloudOpen(true);
    } else {
      setCloudOpen(false);
    }

    if (currentQuiz === 7) {
      setShowChristmasTree(true);
    } else {
      setShowChristmasTree(false);
    }

    if (currentQuiz === 8) {
      // 每兩秒換一次背景
      const intervalId = setInterval(() => {
        setCurrentImageIndexQ8((prevIndex) => (prevIndex + 1) % imagesQ8.length);
      }, 2000);

      return () => clearInterval(intervalId); // 清除定時器
    }
  }, [currentQuiz]);

  const handleMusic = () => {
    setCloseMusicCloud(true);
    const audio = document.getElementById('xm-music');
    if (musicOn) {
      audio.setAttribute('data-playing', 'true');
      audio.play();
    }
  };

  const handleLoadingDone = (status) => {
    // is status arr all false, then set loading to false
    if (status.every(s => !s)) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const finishQuiz = () => {
    setReadyToShowResult(true);
    setNotYetReadyToShowResult(false);

    setTimeout(() => {
      setReadyToShowResult(false);
    }, 2000);
  };

  return (
    <>
      <LineBrowserRedirect />
      <div className="pc-title">
        <img src={Title} alt="Title" />
      </div>
      <div className="container-wrapper">
        <div className="container">
          {
            showChristmasTree ?
              <img src={Q7BG} alt="Q7 BG" className="q7-bg" />
              : null
          }
          {
            currentQuiz === 8 ?
              <div className="bg-q8">
                {imagesQ8.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`BG Q8-${index + 1}`}
                    className={`bg-q8-image ${index === currentImageIndexQ8 ? 'active' : ''}`}
                  />
                ))}
              </div>
              : null
          }

          <button className="music-btn" onClick={() => { toggleMusic() }}>
            {
              musicOn ? <img src={IconMusic} alt="Music On" /> : <img src={IconMusicOff} alt="Music Off" />
            }
          </button>
          <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<PageWrapper><Home /></PageWrapper>} />
              <Route path="/quiz" element={<PageQuiz currentQuiz={setCurrentQuiz} goToResult={() => finishQuiz()} />} />
              <Route path="/result" element={<PageResult />} />
              <Route path="/ar" element={<PageAR />} />
              <Route path="/magic" element={<PageMagic />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </AnimatePresence>

          {/* 音樂雲 */}
          {
            // TODO: 暫時關閉
            closeMusicCloud ? null : (
              <div className="music-cloud-mask">
                <div className="music-cloud">
                  <img src={MusicCloud} alt="Music Cloud" />
                  <div className="music-cloud-content">
                    <img src={MusicImg} alt="Music On" />
                    <p>開啟聲音，獲得更好的體驗</p>
                    <button onClick={() => { handleMusic() }}>
                      好的
                    </button>
                  </div>
                </div>
              </div>
            )
          }

          {/* Loading Bear */}
          {
            !loading ?
              null :
              <div className="loading-bear">
                <img src={IconLoading} alt="Loading Bear" />
                <p>載入中...</p>
              </div>
          }

          {/* Q5 - 雲散開特效 */}
          {
            currentQuiz === 5 &&
            <div className="scene-change-cloud">
              <img src={CloudLeft} alt="Cloud Left" className={`cloud-left ${cloudOpen ? 'open' : ''}`} />
              <img src={CloudRight} alt="Cloud Right" className={`cloud-right ${cloudOpen ? 'open' : ''}`} />
            </div>
          }

          {/* 結果頁遮罩 */}
          <div className={`result-mask ${readyToShowResult ? 'show' : ''}`} />

        </div>
      </div >

      {/* QR　CODE */}
      <div className="qr-code" >
        <img src={QRCode} alt="QR Code" />
      </div>

      {/* 音樂 */}
      <audio id="xm-music" src={XmasBGMusic} loop data-playing="false" />

      <ImagePreloader srcArray={imageSources} loadStatus={status => handleLoadingDone(status)} />
    </>
  );
};

// PageWrapper component
const PageWrapper = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ position: "relative" }}
    >
      {children}
    </motion.div>
  );
};

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
