import './css/q4.scss';

import React, { useState } from 'react';
import Car from 'src/assets/img/q4/car.png';

const AnswerItem = ({ isActive, onClick, children }) => {
  return (
    <div className={`answer-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <p>{children}</p>
    </div>
  );
};

const Q4 = (props) => {

  return (
    <div className="q4-container mt-50">
      <p className="quiz-p">
        塵封已久的魔法雪橇，需要注入珍貴<br />
        的回憶才能啟動，這讓你想起什麼？
      </p>
      <div className="cart-type">
        <img src={Car} alt="Car" />
      </div>
      <div className="answer-container">
        <AnswerItem isActive={props.answer === 1} onClick={() => props.pickAnswer(1)}>
          朋友們一起歡笑打鬧的時光
        </AnswerItem>
        <AnswerItem isActive={props.answer === 2} onClick={() => props.pickAnswer(2)}>
          家人們溫馨團聚的時刻
        </AnswerItem>
        <AnswerItem isActive={props.answer === 3} onClick={() => props.pickAnswer(3)}>
          和心愛的人牽手漫步的甜蜜
        </AnswerItem>
        <AnswerItem isActive={props.answer === 4} onClick={() => props.pickAnswer(4)}>
          努力付出後獲得成功的喜悅
        </AnswerItem>
      </div>
    </div>
  );
}

export default Q4;
