import './css/q8.scss';

import React from 'react';

const AnswerItem = ({ isActive, onClick, children }) => {
  return (
    <div className={`answer-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      <p>{children}</p>
    </div>
  );
};

const Q8 = (props) => {

  return (
    <div className="q8-container mt-50">
      <p className="quiz-p">
        耶誕夜的重頭戲！ <br />
        你想用什麼方式讓新北耶誕城更精彩？
      </p>
      <div className="answer-container">
        <AnswerItem isActive={props.answer === 1} onClick={() => props.pickAnswer(1)}>
          施放華麗的高空煙火點綴星空
        </AnswerItem>
        <AnswerItem isActive={props.answer === 2} onClick={() => props.pickAnswer(2)}>
          讓天空降下傳遞幸福的流星雨
        </AnswerItem>
        <AnswerItem isActive={props.answer === 3} onClick={() => props.pickAnswer(3)}>
          變出雪花紛飛的夢幻仙境
        </AnswerItem>
        <AnswerItem isActive={props.answer === 4} onClick={() => props.pickAnswer(4)}>
          召喚小精靈變出各式各樣的禮物
        </AnswerItem>
      </div>
    </div>
  );
}

export default Q8;
