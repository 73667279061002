import './css/q1.scss';

import React, { useState, useEffect } from 'react';
import Q1Cloud from 'src/assets/img/q1/box.png';
import Q1Title from 'src/assets/img/q1/title.png';

const Q1 = (props) => {
  const maxNameLength = 10;
  const [playerName, setPlayerName] = useState('');

  useEffect(() => {
    setPlayerName(props.playerName);
  }, [props.playerName]);

  const handleSetName = (value) => {
    // Always update the local playerName but limit to maxNameLength characters
    if (value.length <= maxNameLength) {
      setPlayerName(value);
    }

    // Apply validation only if the input matches the allowed characters
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
    if (reg.test(value) || value === '') {
      if (value.length <= maxNameLength) {
        props.setPlayerName(value);
      }
    }
  }

  const handleNextQuiz = (num) => {
    if (props.playerName === '') {
      alert('請輸入名字');
      return;
    }
    props.nextQ(num);
  }

  return (
    <div className="q1-container">
      <img src={Q1Title} alt="Q1 Title" />

      <div className="q1-content">
        <div className="q1-cloud">
          <img src={Q1Cloud} alt="Q1 Cloud" />
          <div className="q1-form">
            <div className="q1-form-group">
              <label>請輸入你的名字</label>
              <input
                type="text"
                value={playerName}
                onChange={(e) => { handleSetName(e.target.value) }}
              />
              <button onClick={() => { handleNextQuiz(1) }}>
                輸入好了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Q1;
